import React from 'react';
import AppHeader from './components/AppHeader';
import TopNavigation from './components/TopNavigation';
import Projects from './components/Projects';
import makeStyles from "@material-ui/core/styles/makeStyles";
import BlogPosts from './components/BlogPosts';
import AboutMe from './components/AboutMe';
import DanceClips from './components/DanceClips';

const useStyles = makeStyles({
    divider: {
        borderTop: '1px solid #dbdbdb',
        marginTop: "2rem",
        marginBottom: "2rem",
    },
});

function App() {
    const classes = useStyles();

    return (
        <div className="App">
            <header>
                <AppHeader
                    title="코딩소년"
                    subTitle="프로그래머ㆍ스트릿 댄서"
                />
            </header>
            <nav>
                <TopNavigation
                    navItems={["사내 프로젝트", "개인 프로젝트", "블로그", "댄스", "소개"]}
                />
            </nav>
            <article className={classes.articleContainer}>
                <Projects
                    title="사내 프로젝트"
                    projects={[
                        {
                            img: "/img/appicon/coupang.webp",
                            title: "쿠팡(Coupang)",
                            desc: "앱 전반에 존재하는 광고 관련 서비스 개발, DCO(Dynamic Creative Optimization)",
                            company: "ⓒ coupang",
                            urlCompany: "https://www.coupang.com/",
                            urlAppAnd: "https://play.google.com/store/apps/details?id=com.coupang.mobile",
                        },
                        {
                            img: "/img/appicon/toss.webp",
                            title: "토스",
                            desc: "만보기, 카드 맞춤추천, 토스카드, 송편 지원금 등의 서비스 개발",
                            company: "ⓒ Viva Republica",
                            urlCompany: "https://toss.im/",
                            urlAppAnd: "https://play.google.com/store/apps/details?id=viva.republica.toss"
                        },
                        {
                            img: "/img/appicon/wav.webp",
                            title: "WAV",
                            desc: "미국 내 힙합, EDM 아티스트 플랫폼. 라이브 스트리밍 방송, 기프팅 등",
                            company: "ⓒ NAVER",
                            urlCompany: "https://www.naver.com/",
                            urlAppAnd: "https://play.google.com/store/apps/details?id=tv.wavmusic.android",
                            urlWeb: "https://wav.media/",
                        },
                        {
                            img: "/img/appicon/pholar.webp",
                            title: "Pholar",
                            desc: "관심사 기반 SNS 서비스. 초기 단계부터 메인 개발자로 참여. 2016년 8월 말까지 담당",
                            company: "ⓒ NAVER",
                            urlCompany: "https://www.naver.com/",
                            urlAppAnd: "https://play.google.com/store/apps/details?id=com.naver.android.pholar"
                        },
                        {
                            img: "/img/appicon/naver.webp",
                            title: "네이버 - Naver",
                            desc: "네이버 앱. 단말의 시스템 윈도우에서 바로 접근이 가능한 퀵메뉴 개발",
                            company: "ⓒ NAVER",
                            urlCompany: "https://www.naver.com/",
                            urlAppAnd: "https://play.google.com/store/apps/details?id=com.nhn.android.search",
                        },
                        {
                            img: "/img/appicon/v3mobile.webp",
                            title: "V3 Mobile",
                            desc: "모바일 백신. 백신 엔진 및 엔진이 사용하는 시그니처 빌더, 파서, APK 분석 툴",
                            company: "ⓒ Ahnlab",
                            urlCompany: "https://global.ahnlab.com/site/main.do",
                            urlAppAnd: "https://play.google.com/store/apps/details?id=com.ahnlab.v3mobilesecurity.soda"
                        },

                    ]}
                />

                <hr className={classes.divider} />

                <Projects
                    title="개인 프로젝트"
                    projects={[
                        {
                            img: "/img/appicon/parrotplayer.webp",
                            title: "패럿플레이어",
                            desc: "영어학습 앱. 유튜브, 단말에 있는 영상을 자막과 함께 재생, 독서모드, 단어장, 다크테마 등의 기능 지원",
                            company: "ⓒ me",
                            urlCompany: "https://jmlee.me/",
                            urlAppAnd: "https://play.google.com/store/apps/details?id=com.jeongmin.parrotplayer",
                        },
                        {
                            img: "/img/appicon/pickle.webp",
                            title: "피클",
                            desc: "채팅창을 모티브로 한 메모장, 다양한 테마, OGTag, 유튜브 영상 재생, 위젯 등을 제공",
                            company: "ⓒ me",
                            urlCompany: "https://jmlee.me/",
                            urlAppAnd: "https://play.google.com/store/apps/details?id=com.afn.pickle",
                        },
                        {
                            img: "/img/appicon/fontbox.webp",
                            title: "폰트박스",
                            desc: "머신러닝을 이용하여 이미지에 있는 폰트를 식별하고, 가장 가까운 폰트를 찾아주는 서비스",
                            company: "ⓒ me",
                            urlCompany: "https://jmlee.me/",
                            urlAppAnd: "https://play.google.com/store/apps/details?id=com.dreamer.fontbox",
                        },
                    ]}
                />

                <hr className={classes.divider} />

                <BlogPosts
                    title="최신 포스트"
                    posts={[
                        {
                            img: "/img/blogpost/light_dark.png",
                            title: "테마(Theme)를 이용해서 다양한 모습의 안드로이드 앱 만들기",
                            desc: "멋쟁이들은 계절이나 분위기에 따라 다양한 색의 옷으로 스스로를 표현합니다. 아름다운 자연도 계절에 따라 완전 다른 모습으로 우리의 눈을 사로잡습니다. 최근 Android, iOS에 많은 화제가 되고 있는 다크 모드(Dark Mode)도 비슷합니다. 모드에 따라 하나의 앱이 밝고, 어두운 두 가지 모습을 자유자재로 오갑니다. 안드로이드에서는 테마(Theme)를 이용해서 이를 구현할 수 있습니다. 계절에 따라 모습이 바뀌는 자연을 생각하면 테마는 계절이라고 생각할 수도 있고, 기분에 따라 옷의 컬러를 선택하는 사람에겐 기분이 테마가 될 수 있습니다.",
                            url: "https://jeongmin.github.io/2020/03/08/android/styling/android-theming/",
                            updatedAt: "2020-03-28",
                        },
                        {
                            img: "/img/blogpost/worker-thread.png",
                            title: "안드로이드의 프로세스, 쓰레드 이해하기",
                            desc: "안드로이드 앱을 사용해봤거나 개발해봤다면 누구나 한번씩은 ANR 다이얼로그나 버벅거림, 혹은 크래시로 앱이 비정상 종료되는 상황을 겪어봤을 겁니다. 이런 현상들은 사용자에게 안좋은 경험을 주고 심한 경우 앱을 지워버리게 만드는 요인이 되기도 합니다. 반대로 빠르고 부드러운 앱은 사용자에게 좋은 인상을 줍니다. 그렇다면 빠르고 크래시 없는 앱을 개발하기 위해서는 어떻게 해야할까요?",
                            url: "https://jeongmin.github.io/2018/06/28/android/android-process-thread/",
                            updatedAt: "2018-06-28",
                        },
                        {
                            img: "/img/blogpost/emacs-package-overview.png",
                            title: "이맥스 패키지 관리 - Package management in Emacs",
                            desc: "이맥스는 ELISP를 통해 필요한 기능을 직접 만들어 쓸 수도 있고, 다른 사람이 만든 패키지를 설치하여 사용할 수도 있습니다. 이런 확장성은 이맥스가 40년이 넘도록 꾸준히 사랑받고, 최신 텍스트 편집기에 비해 전혀 뒤쳐지지 않는 기능을 가질수수 있도록 해주는 원동력이기도 합니다.",
                            url: "https://jeongmin.github.io/2017/11/09/emacs/emacs-package/",
                            updatedAt: "2018-01-01",
                        },
                    ]}
                />

                <hr className={classes.divider} />

                <DanceClips
                    title="댄스"
                />

                <hr className={classes.divider} />

                <AboutMe
                    title="소개"
                    desc="안녕하세요. 저는 프로그래밍을 통해 무언가를 만드는 것이 너무 즐거운 개발자입니다. 저는 프로그래밍을 통해 무엇이든 할 수 있다고 믿습니다. 그리고 이를 통해 세상을 좀 더 좋게 만들 수 있는 사람이 되고 싶습니다. 취미는 스트릿댄스입니다."
                />
            </article>
        </div>
    );
}

export default App;