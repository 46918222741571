import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Chip from '@material-ui/core/Chip';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles({
    root: {
        width: "300px",
        backgroundColor: "#f7f9fa",
        marginLeft: "12px",
        marginRight: "12px",
        borderRadius: "5px",
        border: "1px solid #f7f9fa",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
        padding: "1.4rem",
        textAlign: "center"
    },
    rootSmall: {
        width: "100%",
        backgroundColor: "#f7f9fa",
        marginTop: "12px",
        marginBottom: "12px",
        borderRadius: "5px",
        border: "1px solid #f7f9fa",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
        padding: "1.4rem",
        textAlign: "center"
    },
    img: {
        width: "180px",
        height: "180px",
        display: "block"
    },
    chipCompany: {
        margin: "0.8rem 0"
    },
    desc: {
        fontSize: "0.8rem"
    }
});


export default function ProjectItem(props) {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:768px)');

    function handleChipCompanyClick() {
        window.open(props.urlCompany, '_blank')
    }

    return (
        <li className={matches ? classes.root : classes.rootSmall}>
            <a href={props.urlAppAnd}>
                <img className={classes.img} src={props.img} alt={props.title} />
                <Chip className={classes.chipCompany}
                    label={props.company}
                    clickable={true}
                    color="primary"
                    size="small"
                    onClick={handleChipCompanyClick}
                />
                <div className={classes.desc}>{props.desc}</div>
            </a>
        </li>
    );
}