import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    mainContainer: {
        borderTop: "1px solid #dbdbdb",
        borderBottom: "1px solid #dbdbdb",
        paddingTop: "1.5rem",
        paddingBottom: "1.5rem",
        textAlign: "center"
    },
    navigationItem: {
        fontWeight: "normal",
        padding: "0.5rem"
    },
    navigationItemSelected: {
        fontWeight: "bold",
        padding: "0.5rem"
    },
    navigationItemHover: {
        fontWeight: "bold",
        padding: "0.5rem 0.5rem"
    },
});

function NavigationItem(props) {
    const classes = useStyles();
    const [hover, setHover] = React.useState(false);
    return (
        <span className={hover ? classes.navigationItemHover : classes.navigationItem}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {props.text}
        </span>
    );
}

export default function TopNavigation(props) {
    const classes = useStyles();

    
    const navItems = props.navItems.map(item => {
        return (
            <NavigationItem text={item} />
        )
    });

    return (
        <div className={classes.mainContainer}>
            {navItems}
        </div>
    );
}