import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '1rem',
    },
    gridList: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: "100%",
        padding: "10px",
        overflow: "hidden",
    },
    blogPostContainer: {
        width: "100%",
        overflow: "hidden",
        padding: "1px",
    },
    blogPostRoot: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        borderRadius: "5px",
        border: "1px solid #f7f9fa",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
        color: "#000000",
        backgroundColor: "#f7f9fa",
        margin: "0.6rem 0",
        padding: "1rem",
    },
    blogPostRootSmall: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "5px",
        border: "1px solid #f7f9fa",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
        color: "#000000",
        backgroundColor: "#f7f9fa",
        margin: "0.6rem 0",
        padding: "1rem",
    },
    title: {
        fontWeight: "normal",
        margin: "1rem 0rem",
        textAlign: "center"

    },
    thumbnail: {
        width: "200px",
        maxHeight: "200px",
        objectFit: "cover",
        objectPosition: "center",
        margin: "auto",

    },
    blogPostContent: {
        display: "flex",
        flexGrow: "1",
        flexDirection: "column",
        margin: "auto 0",
        paddingLeft: "1rem",
        minWidth: "0",
    },
    blogPostTitle: {
        fontSize: "1.4rem",
        fontWeight: "bold",
        color: "var(--point-color)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
    },
    blogPostDesc: {
        width: "100%",
        fontSize: "1rem",
        fontWeight: "normal",
        color: "#000000",
        marginTop: "0.4rem",
        display: "-webkit-box",
        WebkitLineClamp: "4",
        WebkitBoxOrient: "vertical",
        wordWrap: "break-word",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    seeMoreNormal: {
        margin: "1rem",
        fontWeight: 'normal',
        alignSelf: 'center',
    },
    seeMoreHover: {
        margin: "1rem",
        fontWeight: 'bold',
        alignSelf: 'center',
    },
});

function BlogPostItem(props) {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:768px)');


    return (
        <a className={classes.blogPostContainer} href={props.url} target="_blank" rel="noopener">
            <li className={matches ? classes.blogPostRoot : classes.blogPostRootSmall}>

                <img className={classes.thumbnail}
                    src={props.img}
                    alt={props.title}>
                </img>
                <div className={classes.blogPostContent}>
                    <h3 className={classes.blogPostTitle}>{props.title}</h3>
                    <div className={classes.blogPostDesc}>{props.desc}</div>
                    <div>{props.updatedAt}</div>
                </div>
            </li>
        </a>
    )
}

export default function BlogPosts(props) {
    const classes = useStyles();
    const [hover, setHover] = React.useState(false);

    return (
        <div className={classes.root}>
            <h2 className={classes.title}>{props.title}</h2>
            <ul className={classes.gridList} cols={props.posts.length}>
                {
                    props.posts.map(item => {
                        return <BlogPostItem className={classes.blogPostRoot}
                            img={item.img}
                            title={item.title}
                            desc={item.desc}
                            url={item.url}
                        />
                    })
                }
            </ul>
            <a className={hover ? classes.seeMoreHover : classes.seeMoreNormal}
                href="https://jeongmin.github.io" target="_blank" rel="noopener"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >전체 포스트 보기
            </a>
        </div>
    );
}