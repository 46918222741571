import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from '@material-ui/core/useMediaQuery';


const useStyles = makeStyles({
    root: {
        width: '100%',
        padding: '0 0.4rem',
        marginTop: '1rem'
    },
    title: {
        fontWeight: 'normal',
        margin: '1rem 0rem',
        textAlign: 'center',
    },    
    clipConatiner: {
        display: 'flex',
        padding: '0',
        flexDirection: 'row',
        justifyContent: 'center',        
    },
    clipConatinerSmall: {
        display: 'flex',
        padding: '0',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    clip: {
        display: 'block',        
        flexGrow: '1',
        flexShrink: '1',
        flexBasis: '0',
        margin: '5px',
        textAlign: 'center'
    },
    youtubeThumbnail: {
        width: '100%',
        maxWidth: '400px',
        borderRadius: '5px',
        border: '1px solid #666',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
    }
});


export default function DanceClips(props) {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:768px)');


    return (
        <div>
            <h2 className={ classes.title}>{props.title}</h2>
            <ul className={matches ? classes.clipConatiner : classes.clipConatinerSmall}>
                <li className={classes.clip}>
                    <a href="https://www.youtube.com/watch?v=FBoVeFJYTxs" target="_blank" rel="noopener">
                        <img className={classes.youtubeThumbnail} src="https://i3.ytimg.com/vi/FBoVeFJYTxs/maxresdefault.jpg" alt="연습" />
                    </a>
                </li>
                <li className={classes.clip}>
                    <a href="https://www.youtube.com/watch?v=-5OrtH5VSOU" target="_blank" rel="noopener">
                        <img className={classes.youtubeThumbnail} src="https://i3.ytimg.com/vi/-5OrtH5VSOU/maxresdefault.jpg" alt="연습" />
                    </a>
                </li>
                <li className={classes.clip}>
                    <a href="https://www.youtube.com/watch?v=7lByrRSOy-s" target="_blank" rel="noopener">
                        <img className={classes.youtubeThumbnail} src="https://i3.ytimg.com/vi/7lByrRSOy-s/maxresdefault.jpg" alt="연습" />
                    </a>
                </li>
            </ul>
        </div>
    );
}