import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    appHeader: {
        margin: "40px"
    },
    appHeaderTitle: {        
        fontSize: "3rem",
        fontWeight: "bold",
        padding: 0,
        textAlign: "center",
        marginBottom: "0.2em"
    },
    appHeaderSubTitle: {
        fontSize: "1rem",
        color: "#4a4a4a",        
        textAlign: "center",
        fontWeight: "bold"
    }
});

export default function AppHeader(props) {
    const classes = useStyles();

    return (
        <div
            className={classes.appHeader}>
            <div className={classes.appHeaderTitle}>
                {props.title}
            </div>
            <div className={classes.appHeaderSubTitle}>
                {props.subTitle}
            </div>
            
        </div>
    );
}